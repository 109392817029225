.courseRegistrationContainer{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding: 0 var(--spacing-20px);
    height: calc(100% - 110px);
    overflow: hidden;
}
.courseRegisterationTableOuter{
    width: 100%;
    height: calc(100% - 50px);
   
    overflow: auto;
    background: var(--white);
}
.courseRegistrationTable{
    width: 100%;
}
.courseRegistrationTable{
    width: 100%;
    text-align: left;
}

.courseRegistrationTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;   
}


.courseRegistrationTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}

.courseRegistrationTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.courseRegistrationTable tbody tr:hover td{
    background: var(--grey-1);
}

.courseRegistrationTable .checkmark{
    top: -16px;
}
.courseRegistrationTable th:first-child, .courseRegistrationTable td:first-child{
    width: 10px;
}
.courseTableContainer {
    overflow: hidden;
    position: relative;
    width: 49%;
    height: 100%;
    border: 1px solid var(--grey-3);
    border-radius: 6px;
}