.confirmationPopupContainer {
  width: 420px;
  height: max-content;
  background: var(--white);
  border-radius: 6px;
}

.popupBtnGrpNew-Logout {
  gap: var(--spacing-12px);
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupText-logout {
  font-size: 16px;
  line-height: 150%;
  text-align: center;
}
.popupText b {
  font-family: "ns-bold";
}

.popupTitle {
  font-family: "ns-semibold";
}
