.announcementPopupContainer{
    width: 700px;
    border-radius: 6px;
    background: var(--white);
}

.popupHeaderLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.announcementImg{
    width: 100%;
    object-fit: cover;
    height: 300px;
}

.popupBody .content{
    margin-top: var(--spacing-12px);
    font-size: 14px;
    line-height: 20px;
    color: var(--black-2);
}

.announcementPopupContainer .popupHeader{
    border: none;
    padding-bottom: 0;
}

.announcementPopupContainer .popupBody img{
    height: 400px;
    width: 500px;
    object-fit:contain;
}

