.courseDetailsOuter{
    padding-top: 20px;
    max-width: 1000px;
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.courseDetailsMenuOuter{
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 350px;
    height: 100%;
}
.courseDetailsMenuOuter::-webkit-scrollbar{
    display: none;
}

.courseContentOuter{
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
    width: calc(100% - 400px);
    gap: var(--spacing-8px);
}

.courseContentOuter::-webkit-scrollbar{
    display: none;
}

.sessionDetailsOuter{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}
.sessionDetials{
    margin-top: var(--spacing-12px);
    margin-bottom: var(--spacing-8px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.sessionName {
    margin-top: var(--spacing-8px);
    font-size: 20px;
    line-height: 28px;
    color: var(--black-5);
    font-family: 'ns-bold';
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    /* max-height: 50px; */
    white-space: nowrap;
    width: 90%;
}


.sessionDetials p{
    font-family: 'ns-semibold';
    font-size: 14px;
    color: var(--black-1);
}
.sessionDetials p:last-child{
    color: var(--success-4);
}

.courseContentBox{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border: 1px solid var(--grey-3);
    padding: 20px 16px;
    border-radius: 10px;
    background: var(--white);
    transition: 0.4s;
}

.courseContentBox.active{
    height: max-content;
}

.courseContentBoxBody{
    display: none;
}
.courseContentBox.active .courseContentBoxBody{
    display: block;
}
.courseContentBoxHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.toggleBtn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.courseContentBox.active .toggleBtn img{
    transform: rotate(180deg);
}

.toggleBtn p{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-1);
}

.greyTag{
    width: max-content;
    font-size: 12px;
    line-height: 16px;
    font-family: 'ns-semibold';
    padding: 4px 8px;
    color: var(--black-4);
    border-radius: 2px;
    background-color: var(--grey-3);
}

.primaryTag{
    display: flex;
    align-items: center;
    gap: 5px;
    width: max-content;
    font-size: 12px;
    line-height: 16px;
    font-family: 'ns-semibold';
    padding: 4px 8px;
    color: var(--primary-4);
    border-radius: 2px;
    background-color: var(--primary-1);
}

.successTag{
    width: max-content;
    font-size: 12px;
    line-height: 16px;
    font-family: 'ns-semibold';
    padding: 4px 8px;
    color: var(--success-4);
    border-radius: 2px;
    background-color: var(--success-1);
}

.dangerTag{
    width: max-content;
    font-size: 12px;
    line-height: 16px;
    font-family: 'ns-semibold';
    padding: 4px 8px;
    color: var(--danger-4);
    border-radius: 2px;
    background-color: var(--danger-1);
}

.toggleBtnIcon{
    background: var(--grey-2);
    border-radius: 50%;
    padding: 4px;
    
}
.courseContentBodyHeader{
    width: 100%;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.currentStatus{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    margin-top: var(--spacing-8px);
}
.currentStatus p{
    font-size: 14px;
    font-family: 'ns-semibold';
}

.successText{
    color: var(--success-4);
}
.secondaryText{
    color: var(--black-2);
}
.videoBox{
    margin-top: var(--spacing-12px);
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
}

.videoBox img{
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.sessionDescription{
    margin-top: var(--spacing-8px);
    font-size: 16px;
    font-family: 'ns-regular';
    line-height: 22px;
    color: var(--black-3);
    margin-bottom: var(--spacing-12px);
}

.fileContainer{
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
    width: 100%;
}

.hrLine{
    height: 1px;
    width: 100%;
    background: var(--grey-3);
}

.tags{
    gap: var(--spacing-4px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.courseContentFooter{
    padding-top: var(--spacing-12px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dueDate{
    background: var(--grey-2);
    padding: 8px;
   
}
.dueDate p{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-3);
}

.dueDate p span{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--danger-4);
}

.submitDate{
    background: var(--grey-2);
    padding: 8px;
}

.submitDate p{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--black-3);
}

.submitDate p span{
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--success-4);
}

.courseDetailsMenuHeading{
    margin-top: var(--spacing-28px);
    margin-bottom: var(--spacing-12px);
    text-transform: uppercase;
    font-family: 'ns-semibold';
    font-size: 14px;
    color: var(--black-4);
}

.sessionOverviewCard{
    cursor: pointer;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}
.sessionCardHeader{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.sessionOverviewCard.active{
    border-radius: 8px;
    border: 1px solid var(--grey-3);
    background: var(--white);
}

.sessionCardDueDate{
    font-size: 12px;
    color: var(--black-1);
}

.sessionCardDueDate span{
    color: var(--black-5);
}

.sessionCardHeaderLeft{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.sessionCardHeaderTop{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    margin-bottom: var(--spacing-4px);
}

.pointerScale{
    margin: var(--spacing-16px) 0 var(--spacing-8px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
}
.point{
    min-width: 50px;
    height: 5px;
    background: var(--grey-3);
}
.point.active{
    background: var(--success-4);
}
.point:first-child{
    border-radius: 5px 0 0 5px;
}

.point:last-child{
    border-radius: 0 5px 5px 0;
}

.points{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-5);
    text-align: right;
    font-family: 'ns-bold';
    margin-bottom: var(--spacing-8px);
}

.points.active{

    color: var(--success-4);
}




