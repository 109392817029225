.biddingContainer{
    position: relative;
    width: calc(100% - 40px);
    margin: 0 auto;
    border-radius: 6px 6px 0 0;
    border: 1px solid var(--grey-3);
    overflow: hidden;
    background: var(--white);
    height: calc(100% - 240px);
}
.biddingTableOuter{
    overflow: auto;
    
    border-bottom: none;
    height: calc(100% - 56px);
}
.biddingTable{
    width: 100%;
    text-align: left;
}

.biddingTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;   
}


.biddingTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}

.biddingTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.biddingTable tbody tr:hover td{
    background: var(--grey-1);
}
.fixedRowBottom{
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    border-radius: 0 0 6px 6px;
    width: 100%;
    padding: 10px 20px;
    background: var(--white);
    justify-content: space-between;
}

.fixedRowBottom p{
    font-size: 14px;
    font-family: 'ns-semibold';
}

.student-profile-outer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto 0px;
    width: calc(100% - 40px);
}
.student-profile-left{
    width: 15%;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: var(--white);
    padding: 25px 25px;
    border-radius: 5px;
    border: 1px solid var(--grey-3);
    min-height: 160px;
}
.student-profile-right{
    width: 74%;
    max-width: 650px;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    padding: 25px 50px;
    border-radius: 5px;
    border: 1px solid var(--grey-3);
}
.student-details{
    display: flex;
    gap: 5px;
    flex-direction: column;
}

.student-details .name{
    font-size: 18px;
    font-family: 'ns-bold';
    color: var(--black);
}
.student-details .id{
    font-size: 14px;
    color: var(--dark-grey);
    font-family: 'ns-bold';
}
.student-details .batch{
    font-size: 14px;
    font-family: 'ns-regular';
    color: var(--dark-grey);
}
.policy-header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    line-height: 150%;
    margin-bottom: 10px;
}
.policy-header p{
    font-family: 'ns-bold';
    font-size: 18px;
}
.dot-list-item{
    color: var(--dark-grey);
    font-size: 13px;
    font-family: 'ns-regular';
    line-height: 150%;
}
.myCourse .feedback-container{
    height: calc(100% - 260px);
}
.table tr:nth-last-child(-n + 4) .action-dropdown {
    bottom: 0;
    top: auto;
}
.table tr:nth-child(-n + 4) .action-dropdown {
    top: 15px !important;
    bottom: auto !important;
}
.primary-text{
    color: var(--primary-5);
}

.item{
    font-size: 18px;
}

.count{
    font-size: 40px;
}

.item2{
    font-size: 12px;
}

.coursePreferenceOuter{
    width: 100%;
    height: calc(100% - 65px);
    overflow: auto;
}