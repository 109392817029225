.feedbackPopupContainer{
    width: 420px;
    position: relative;
    height: auto;
    border-radius: 6px;
    background: var(--white);
}

.question{
    color: var(--black-5);
    font-size: 16px;
    font-family: 'ns-bold';
    margin-bottom: var(--spacing-12px);
}

.feedbackPopupContainer .popupFooter{
    position: relative;
    border: none;
    gap: var(--spacing-8px);
}