.sideMenu{
    left: -250px;
    top: 0px;
    position: fixed;
    height: calc(100vh - 65px);
    width: 250px;
    background: var(--black-5);
    transition: 0.3s;
}
.sideMenu a{
    text-decoration: none;
}
.sideMenuOuter.active .sideMenu{
    left: 0px;
}
.sideMenuList{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sideMenuListItem{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
    padding: 16px 28px;
    font-family: 'ns-regular';
    color: var(--white);
    font-size: 16px;
    transition: 0.3s;
}
.sideMenuListItem:hover{
    background: var(--primary-4);
}
.border-top{
    border-top:1px solid var(--black-4) ;
}

.sideMenuOuter{
    position: fixed;
    height: calc(100% - 65px);
    top: 65px;
    z-index: 100;
    width: 100%;
    background:  rgba(23, 23, 23, 0.4);
    backdrop-filter: blur(5px);
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
}

.sideMenuOuter.active{
    visibility: visible;
    opacity: 1;
}