.clubDetailsContainer{
    width: 100%;
    height: 100%;
    
}
.clubDetailsContainerOuter{
    width: 100%;
    height: calc(100% - 66px);
    padding: var(--spacing-20px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
}
.clubDetailsCenterContainer{
    width: 800px;
    display: flex;
    gap: var(--spacing-8px);
    flex-direction: column;
}

.clubHeaderOuter{
    width: 100%;
    height: 320px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

}

.clubHeaderImg{
    width: 100%;
    background-color: var(--grey-3);
    height: 190px;
}

.clubHeaderImg img{
    width: 100%;
    background-color: var(--black-3);
    object-fit: contain;
}

.clubBody{
    padding: var(--spacing-12px) var(--spacing-20px);
    position: relative;
    width: 100%;
    height: calc(100% - 190px);
    background: var(--white);
}

.clubAvtar{
    border: 1px solid var(--grey-4);
    border-radius: 50%;
    width: 72px;
    height: 72px;
    object-fit: cover;
    position: absolute;
    top: -50px;
    left: 20px;
}

.clubDetailsOuter{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.clubDetail:first-child{
    width: 60%;
}
.clubDetail:last-child{
    width: 40%;
   display: flex;
   align-items: center;
   justify-content: flex-end;
}

.clubDetail .clubInfo{
    font-size: 12px;
    line-height: 16px;
    color: var(--black-3);
}

.clubDetailHeader{
    margin-top: var(--spacing-20px);
    margin-bottom: var(--spacing-4px);
    display: flex;
    gap: var(--spacing-4px);
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.clubDetail .clubName{
    
    font-size: 16px;
    font-family: 'ns-semibold';
}

.clubBodyOuter{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.clubBodyBox:first-child{
    width: 500px;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid var(--grey-3);
}

.clubBodyBox:last-child{
    display: flex;
    flex-direction: column;
    width: calc(100% - 510px);
}
.boxHeader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: var(--white);
}

.boxHeader .toggler{
    font-family: 'ns-semibold';
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    color: var(--grey-5);
    padding: var(--spacing-16px) var(--spacing-8px);
}

.boxHeader .toggler.active{
    color: var(--black-3);
    border-bottom: 1px solid var(--black-3);
}

.boxBody table{
    border-top: 1px solid var(--grey-3);
    width: 100%;
}

.boxBody td{
    border-bottom: 1px solid var(--grey-3);
    font-size: 14px;
    line-height: 20px;
    color: var(--black-1);
    padding: var(--spacing-12px) var(--spacing-12px);
    background: var(--white);
    transition: 0.3s;
}

.boxBody tr:hover td{
    color: var(--primary-6);
    background: var(--grey-1);
}

.boxFooter{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: var(--spacing-12px);
    background: var(--white);
}

.boxFooter .footerText{
    font-size: 13px;
    cursor: pointer;
    font-family: 'ns-semibold';
    color: var(--primary-4);
    line-height: 150%;
}

.presidentInfoBox{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-8px);
    padding: var(--spacing-16px);
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    background: var(--white);
}

.presidentInfoBox img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--black-3);
}
.presidentInfo{
    width: 81%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.nameBox{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.nameBox .name{
    font-family: 'ns-semibold';
    font-size: 14px;
    color: var(--black-4);
}

.programName{
    font-size: 12px;
    line-height: 16px;
    color:  var(--black-3);
}

.clubBodyBox.slider{
    width: 490px;
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    background: var(--white);
    padding: var(--spacing-16px) var(--spacing-20px);
}