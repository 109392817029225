.pageHeaderType2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: var(--white);
    padding: var(--spacing-8px) var(--spacing-20px);
}   
.pageHeaderLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
    gap: var(--spacing-12px);
}

.pageHeaderRight{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 15%;
    gap: var(--spacing-12px);
}

.pageHeaderRight .percent{
    margin: 0;
    width: max-content;
}

.attendanceListContainerOuter{
    width: 700px;
    height: calc(100vh - 105px);
    margin: auto;
    padding: var(--spacing-20px);
}

.attendanceListContainer{
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    background: var(--white);
    max-height: calc(100vh - 200px);
    overflow: auto;
}

.attendanceListTable{
    width: 100%;
    position: relative;
    text-align: left;
    border-collapse: collapse;
}

.attendanceListTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;   
}


.attendanceListTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.attendanceListTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.attendanceListTable tbody tr:hover td{
    background: var(--grey-1);
}

.attendanceListContainerHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: var(--spacing-20px);
}

.attendanceListContainerHeader .formGroup{
    width: auto;
    
}

.attendanceListContainerHeader .react-date-picker__wrapper{
    background: var(--white);
}