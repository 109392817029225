.profileContainerOuter{
    padding:var(--spacing-20px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: calc(100% - 37px);
}

.profileContainer{
    height: 100%;
    width: 1000px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.profileLeft{
    width: 350px;
}

.profileProgressBox{
    gap: var(--spacing-8px);
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--white);
    padding: var(--spacing-20px);
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    margin-bottom: var(--spacing-20px);
}

.userProfile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.userProfile .name{
    font-size: 18px;
    font-family: 'ns-semibold';
}

.progressContent{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
}

.progressContent p{
    font-size: 14px;
    color: var(--black-1);
    font-family: 'ns-semibold';
}

.progressContent p:last-child{
    color: var(--success-4);
}

.proflieTabs{
    gap: var(--spacing-8px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: var(--spacing-20px);
}

.profileTab{
    cursor: pointer;
    border-radius: 6px;
    display: flex;
    gap: var(--spacing-8px);
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    transition: 0.3s;
    padding: var(--spacing-16px);
}

.profileTab.active{
    color: var(--primary-4);
    background: var(--white);
}
.profileTab.active svg path{
    fill: var(--primary-4);
}

.profileRight{
    width: 600px;
    height: 100%;
}

.profileRightContainer{
    background: var(--white);
    border-radius: 6px;
    padding: var(--spacing-20px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: var(--spacing-8px);
    border: 1px solid var(--grey-3);
}
.profileRightContainer img{
    width: 76px;
    height: 76px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--black-5);
}

.profileRightContent{
    display: flex;
    flex-direction: column;
    width: calc(100% - 85px);
    align-items: flex-start;
    justify-content: flex-start;
}

.profileHeader{
    margin-bottom: var(--spacing-8px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.profileHeader .name{
    font-size: 20px;
    font-family: 'ns-semibold';
}

.userText{
    font-size: 14px;
    color: var(--black-3);
}

.basicInfomationBox{
    height: max-content;
    overflow: hidden;
    border-radius: 6px;
    width: 600px;
    background: var(--white);
    border: 1px solid var(--grey-3);
}

.basicInfoHeader{
    width: 100%;
    display: flex;
    align-items: center;
    padding: var(--spacing-20px);
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-3);
}
.basicInfoHeader .heading{
    font-size: 18px;
    font-family: 'ns-semibold';
}

.basicInfomationBox .form{
    padding: var(--spacing-20px);
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-16px);
}

.updateUserImg{
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid var(--grey-3);
}