.announcementContainerOuter{
    width: 100%;
    display: flex;
    padding: var(--spacing-20px);
    align-items: flex-start;
    justify-content: center;
    height: calc(100vh - 110px);
}
.announcementContainer{
    width: 800px;
    margin: auto;
    height: 100%;
    overflow: auto;
    background: var(--white);
    border: 1px solid var(--grey-3);
    border-radius: 6px;
}

.announcementList{
    list-style: none;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.announcementListItem{
    padding: var(--spacing-12px) 0;
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
}

.announcementListItem .contentLeft{
    width: 85%;
    
}
.announcementBox{
    width: 100%;
    padding: var(--spacing-20px) var(--spacing-20px) var(--spacing-12px);
    /* border-bottom: 1px solid var(--grey-3); */
}

.announcementListItem .text{
    display: -webkit-box;
    line-height: 20px;
    margin-top: var(--spacing-4px);
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.announcementBox .heading{
    font-family: 'ns-semibold';
}

