.headerOuter{
    width: 100%;
    position: sticky;
    top: 0;
    background: var(--black-5);
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--black-4);
}

.headerLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.headerCenter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.headerRight{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.headerCenter .programName{
    font-size: 20px;
    color: var(--grey-1);
}

.userImg{
    object-fit: cover;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid var(--black-3); 
}
.studentDetails{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:flex-start;
}

.studentDetails .studentName{
    font-size: 14px;
    color: var(--white);
}
.studentDetails .studentId{
    font-size: 12px;
    color: var(--grey-5);
}

.hamburger{
    transition: 0.3s;
}

.hamburger.active{
    transform: rotate(90deg);
}