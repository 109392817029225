.cvPopupContainer{
    width: 600px;
    height: auto;
    background: var(--white);
    border-radius: 6px;
}

.cvPopupContainer .studentImg{
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 1px solid var(--black-5);
    object-fit: cover;
}

.studentBasicInfoOuter{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: var(--spacing-20px);
}

.studentBasicInfo{
    width: 30%;
}
.studentBasicInfo .heading{
    font-size: 14px;
    font-family: 'ns-regular';
    color: var(--black-1);
}
.studentBasicInfo .content{
    font-family: 'ns-bold';
    margin-top: 5px;
    color: var(--black-5);
}
.studentBasicInfo .content.primaryText{
    color: var(--primary-4);
    text-decoration: underline;
}

.cvImg{
    width: 100%;
    height: auto;
}