.clubRatingPopup{
    width: 420px;
    height: max-content;
    background: var(--white);
    border-radius: 6px;
}

.starRating {
	position: relative;
	display: flex;
    align-items: center;
    justify-content: flex-start;
	border: none;
}

.starRating input {
	border: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	opacity: 0;
}

.starRating label {
	position: relative;
	float: right;
	color: #c8c8c8;
}

.starRating label {
	
	display: inline-block;
	
	fill: #ccc;
	user-select: none;
}

.starRating label svg {
	height: auto;
}

.starRating input:checked ~ label svg path {
	fill: #ffc107;
}

.starRating label:hover ~ label svg path {
	fill: #ffdb70;
}

.starRating label:hover svg path {
	fill: #ffc107;
}

.starRating [type="radio"]:checked + label:before, .starRating [type="radio"]:not(:checked) + label:before{
    display: none;
}

.starRating [type="radio"]:checked + label:after{
    display: none;
}

.starRating [type="radio"]:checked + label, .starRating [type="radio"]:not(:checked) + label{
    width: auto;
    padding: 0;
    margin: 0;
}
.popupHeader img{
    cursor: pointer;
}