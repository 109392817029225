.flex-start {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.gap0 {
    gap: 0;
}

.calender-label {
    width: 100%;
    font-family: "ns-semibold";
    font-size: 14px;
    line-height: 20px;
}


.react-date-picker__wrapper {
    min-width: 260px;
    min-height: 32px;
    padding: 6px 8px;
    border-radius: 6px;
    border: 1px solid var(--grey-3);
    color: var(--black-4);
    cursor: pointer;
    /* background: var(--grey-1); */

    /* background: var(--white) url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmcalender.svg);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 97% 50%; */
}

.react-date-picker__inputGroup__input {
    color: var(--grey-4);
    cursor: pointer;
}

.react-date-picker__inputGroup__input:focus-visible {
    box-shadow: none;
    border: none;
    outline: none;
    background: transparent;
}

.react-date-picker__inputGroup__input:focus {
    background: transparent;
}

.react-date-picker__calendar {
    z-index: 2;
}

/* .react-date-picker__button:enabled{
    display: none;
} */


/* .react-date-picker__button{
    display: none;
} */

.react-date-picker__clear-button {
    display: none;
}

.react-date-picker__calendar-button {
    position: relative;
    padding: 0;
    min-width: 16px;
}

.react-date-picker__calendar-button::after {
    content: "";
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background: url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmcalender.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.react-date-picker__calendar {
    width: auto;
}

.react-calendar {
    padding: 16px 10px;
    border-radius: 6px;
    margin-top: 2px;
    max-width: 260px;
    border: 1px solid var(--grey-4);
}

.react-calendar__navigation {
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid var(--grey-2);
    height: auto;
}

.react-calendar__navigation button {
    position: relative;
}

.react-calendar__navigation__label {
    order: 1;
    text-align: left;
}

.react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation__prev-button {
    order: 2;
    margin-right: 5px !important;
    color: transparent;
    min-width: 20px !important;
}

.react-calendar__navigation__prev-button::after {
    content: "";
    background: url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmleft.svg);
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
}

.react-calendar__navigation__next-button {
    order: 3;
    color: transparent;
    min-width: 20px !important;
}

.react-calendar__navigation__next-button::after {
    content: "";
    background: url(https://mastersunion.s3.ap-south-1.amazonaws.com/assets/imgV2/datepickerpmright.svg);
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
}

.react-calendar__navigation__label__labelText--from {
    font-size: 12px;
    font-family: 'ns-semibold';
    line-height: 20px;
    color: var(--black-5);
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.react-calendar__tile {
    max-height: 34px;
    border-radius: 50%;
    font-size: 12px;
}

.react-calendar button {
    transition: 0.1s ease-in;
}

.react-calendar__tile--active {
    background-color: var(--primary-4) !important;
    color: var(--white) !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background-color: var(--primary-4) !important;
    transition: 0.1s ease-in !important;
}

.react-calendar button:enabled:hover {
    background-color: var(--primary-1);
    transition: 0.1s ease-in;
}

.isFilled .react-date-picker__wrapper {
    color: var(--black-4)
}

.isFilled .react-date-picker__inputGroup__input {
    color: var(--black-4)
}


/* 
.react-calendar__tile--now {
    background: var(--white);
    color: var(--primary-6) !important;
    border: 1px solid var(--primary-6) !important;
} */