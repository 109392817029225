.leaveApplicationOuter{
    width: 100%;
    padding: var(--spacing-20px);
    height: calc(100vh - 105px);
    overflow: auto;
    display: flex;
    align-items: center;
    gap: var(--spacing-12px);
    justify-content: flex-start;
    flex-direction: column;
}

.leaveApplicationContainer{
    border-radius: 6px;
    position: relative;
    width: 700px;
    height: auto;
    background: var(--white);
    border: 1px solid var(--grey-3);
}
.leaveFrom{
    width: 100%;
    padding: 0 var(--spacing-20px) var(--spacing-20px);
}

.form2Col{
    display: flex;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
}

.form2Col .formGrp{
    width: 50%;
}

.formGrp .radioGrpFlex{
    padding:0;
}

.formGrp label{
    font-size: 14px;
    font-family: 'ns-semibold';
}

.formGrp{
    margin: var(--spacing-20px) 0;
    display: flex;
    gap: var(--spacing-8px);
    flex-direction: column;
    width: 100%;
}

.formGrp .dropdownBox{
    gap: var(--spacing-8px);
}

.formGrp .formGroup{
    gap: var(--spacing-8px);
}

.leaveFormFooter{
    padding: var(--spacing-16px) var(--spacing-20px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    bottom: 0;
    border-top: 1px solid var(--grey-3);
}

.noteBox{
    padding: var(--spacing-16px) var(--spacing-20px);
    width: 700px;
    border-radius: 6px;
    background: var(--warning-1);
    border: 1px solid var(--warning-3);
}

.noteHeading{
    font-size: 14px;
    color: var(--danger-4);
    font-family: 'ns-semibold';
}

.notePoints{
    margin-top: var(--spacing-12px);
}
.notePoint{
    font-size: 14px;
    color: var(--black-3);
}

.additionalText{
    margin-top: var(--spacing-16px);
    color: var(--black-3);
    font-size: 14px;
}