.leaderboardContainer{
    width: 100%;
    max-width: 1050px;
    margin: auto;
    padding: var(--spacing-20px);
    height: calc(100% - 40px);
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.leaderboardLeftContainer{
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16px);
   
}
.leaderboardRightContainer{
    gap: var(--spacing-12px);
    display: flex;
    flex-direction: column;
    width: 600px;
}

.studentCard{
    border-radius: 10px;
    border: 1px solid var(--grey-3);
    padding: var(--spacing-20px) var(--spacing-16px);
    background: var(--white);
}

.studentCardHeader{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.studentCardProgress{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-8px);
}
.progressText{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.progressText p{
    font-family: 'ns-semibold';
    font-size: 12px;
    color: var(--black-1);
}
.progressText p:last-child{
    color: var(--success-4);
}
.studentCard{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: var(--spacing-12px);
}
.studentCardHeader{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
.studentCardHeader p{
    font-size: 18px;
    font-family: 'ns-semibold';
}
.leaderboardCard{
    gap: var(--spacing-12px);
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--grey-3);
    padding: var(--spacing-20px) var(--spacing-16px);
    background: var(--white);
}
.leaderboardCardHeader{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
.leaderboardCardHeader .studentImg{
    width: 76px;
    height: 76px;
    border-radius: 50%;
    border: 1px solid var(--black-5);
}
.studentContentHeader{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
.studentContent {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8px);
}

.studentDescription{
    font-size: 14px;
    color: var(--black-3);
}
.studentContentHeader .studentName{
    font-size: 20px;
    font-family: 'ns-bold';
}

.leaderboardTogglers{
    display: flex;
    align-content: center;
    justify-content: flex-start;
    gap: var(--spacing-20px);
    padding-bottom: var(--spacing-20px);
}
.leaderboardToggler{
    font-size: 14px;
    font-family: 'ns-semibold';
    cursor: pointer;
    border: 1px solid var(--black-5);
    background: var(--white);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-4px);
    padding: var(--spacing-8px) var(--spacing-20px);
    transition: 0.3s;
}
.leaderboardToggler.active{
    color: var(--white);
    background: var(--primary-4);
    border-color: var(--primary-4);
}
.leaderboardTable{
    width: 100%;
    height: auto;
    border-collapse: collapse;
}
.leaderboardTable td{
    border-collapse: collapse;
    font-size: 14px;
    border-bottom: 1px solid var(--grey-3);
    padding: var(--spacing-12px) var(--spacing-20px);
}
.studentDetail{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
    
}
.leaderboardProgress{
    padding: var(--spacing-16px) 0;
    border-top: 1px solid var(--grey-3);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-16px);

}
.leaderboardProgressText{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.leaderboardProgressText p:last-child{
    color: var(--primary-4);
}
.leaderboardTable tr.active{
    background: var(--warning-4);
    border-radius: 6px;
}

.leaderboardTable tr.active td{
    border-top: 1px solid var(--warning-7);
    border-bottom: 1px solid var(--warning-7);
}
.leaderboardTable tr.active td:first-child{
    border-left: 1px solid var(--warning-7);
   
}
.leaderboardTable tr.active td:last-child{
    border-right: 1px solid var(--warning-7);
}

.leaderboardData{
   max-height: 380px;
    background: var(--white);
    height: auto;
    overflow: auto;
}

.leaderboardCard:last-child{
    max-height: 485px;
    height: auto;
    overflow: auto;
}
.badgeContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.badge{
    border-radius:8px;
    width: 125px;
    height: 120px;
    background: var(--grey-2);
    border-radius: 60x;
    display: flex;
    align-items: center;
    justify-content: center;
}
.badge p{
    font-family: 'ns-semibold';
    font-size: 16px;
    color: var(--grey-5);
}

.studentDetailsToggler{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}
.studentDetailToggle{
    width: 100%;
    padding: var(--spacing-16px);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.studentDetailLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.studentDetailLeft svg path{
    fill: var(--black-5);
}