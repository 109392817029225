.loginContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.loginContainerLeft{
    width: 50%;
    flex-direction: column;
    background: var(--black-5);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.loginContainerRight{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    flex-direction: column;
    height: 100%;
    background: var(--white);
}

.loginContainerLeft .heading{
    margin-top: 40px;
    font-family: 'ns-regular';
    font-size: 32px;
    color: var(--grey-1);
    line-height: 40px;
    font-weight: 400;
}

.loginContainerLeft .subTitleText{
    margin-top: 10px;
    font-size: 16px;
    line-height: 22px;
    color: var(--grey-1);
    width: 60%;
    text-align: center;
}
.welcomeText{
    margin-top: 40px;
    font-size: 42px;
    line-height: 52px;
    color: var(--black-5);
}
.welcomeSubTitle{
    font-size: 14px;
    width: 39%;
    text-align: center;
    color: var(--black-1);
}

.formGroup{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

}
.formGroup label{
    font-size: 16px;
    color: var(--black-5);
    font-family: 'ns-semibold';
}

.formGroup .loginInput{
    font-family: 'ns-regular';
    font-size: 16px;
    outline: none;
    padding: 13px;
    border-radius: 8px;
    background: var(--grey-1);
    border: 1px solid  var(--grey-4);
    width: inherit;
}

.loginFormContainer{
    width: 400px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.frogetLink{
    cursor: pointer;
    width: 100%;
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    color: var(--danger-4);
}

.signInButtton{
    cursor: pointer;
    margin-top: 10px;
    padding: 12px;
    width: 100%;
    border: 1px solid var(--black-5);
    border-radius: 8px;
    background-color: var(--black-5);
    color: var(--white);
    font-size: 16px;
    font-family: "ns-semibold";
}
.signInwthGoogle{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
    padding: 12px;
    width: 100%;
    border: 1px solid var(--grey-4);
    border-radius: 8px;
    background-color: var(--white);
    color: var(--black-5);
    font-size: 16px;
    font-family: "ns-semibold";
}
.copyrightText{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.copyrightText p{
    font-family: 'ns-semibold';
    font-size: 14px;
    color: var(--black-2);
    cursor: pointer;
}