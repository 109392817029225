.createMeetingContainerOuter{
    width: 100%;
    padding: var(--spacing-20px);
    display: flex;
    height: calc(100% - 100px);
    align-items: flex-start;
    justify-content: center;
}

.createMeetingContainer{
    width: 700px;
    border-radius: 6px;
    height: auto;
    background: var(--white);
}

.createMeetingForm{
    display: flex;
    gap: var(--spacing-8px);
    flex-direction: column;
    width: 100%;
    padding: var(--spacing-20px);
}

.createMeetingFooter{
    border-top: 1px solid var(--grey-3);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: var(--spacing-16px) var(--spacing-20px);
}
.createMeetingContainer .form2Col{
    justify-content: space-between;
}
.createMeetingContainer .form2Col .formGroup{
    width: 49%;
}
.createMeetingContainer .form2Col .dropdownBox{
    width: 49%;
}

.createMeetingContainer .form2Col .react-date-picker{
    width: 100%;
}

.bookingStatus{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: var(--spacing-20px);
}

.bookingStatusText{
    font-size: 12px;
    color: var(--primary-4);
    opacity: 0;
    visibility: visible;
}

.bookingStatusText.active{
    opacity: 1;
    visibility: visible;
}