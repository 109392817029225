/* Customize the label (the container) */
/* .checkmarkContainerOuter{
  transition: 0.3s;
  padding: var(--spacing-4px);
  border-radius: 6px;
  width: max-content;
}

.checkmarkContainerOuter:hover{
  background: var(--primary-1);
} */
.checkboxContainer {
    width: fit-content;
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: var(--black-5);
    margin-bottom: var(--spacing-12px);
  }
  
  /* Hide the browser's default checkbox */
  .checkboxContainer input {
    display: none;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 2px solid var(--black-5);
    background-color: white;
  }
  
  /* On mouse-over, add a grey background color */
  .checkboxContainer:hover input ~ .checkmark {
    background-color: var(--grey-1);
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkboxContainer input:checked ~ .checkmark {
    background-color: var(--white);
    border-color:var(--primary-4) ;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkboxContainer :has(input:checked){
    color: var(--primary-4);
  }
  
  /* Show the checkmark when checked */
  .checkboxContainer input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkboxContainer .checkmark:after {
    left: 5px;
    top: 1px;
    width: 3px;
    height: 8px;
    border: solid var(--primary-4);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }