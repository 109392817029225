.filterBarLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.helpDeskTableOuter{
    width: calc(100% - 40px);
    margin: auto;
    border: 1px solid var(--grey-3);
    border-radius: 6px;
    background: var(--white);
    overflow: auto;
    height: calc(100vh - 190px);
}

.helpDeskTable{
    text-align: left;
    width: 100%;
}

.helpDeskTable thead{
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}

.helpDeskTable thead th{
    background: var(--black-4);
    color: var(--white);
    font-family: 'ns-semibold';
    font-size: 14px;
    line-height: 20px;
    padding: 12px 20px;
}



.helpDeskTable tbody td{
    font-size: 14px;
    line-height: 20px;
    color: var(--black-4);
    font-family: 'ns-regular';
    background: var(--white);
    padding: 10px 20px;
    border-bottom: 1px solid var(--grey-3);
    transition: 0.3s;
}

.helpDeskTable tbody tr:hover td{
    background: var(--grey-1);
}

.feedbackImgOuter{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.feedbackImgOuter .icon.active svg path{
    fill: var(--primary-4);
}

.filterMenuOuter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    top: 65px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 65px);
    position: fixed;
    z-index: 10;
    background:  rgba(23, 23, 23, 0.4);
    backdrop-filter: blur(5px);
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
}

.filterMenuContainer{
    right: -400px;
    position: relative;
    width: 400px;
    height: 100%;
    background: var(--white);
    transition: .3s;
}

.filterMenuOuter.active .filterMenuContainer{
    right: 0;
}

.filterMenuOuter.active{
    visibility: visible;
    opacity: 1;
}

.filterMenuHeader{
    width: 100%;
    padding: var(--spacing-16px) var(--spacing-20px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey-3);
}

.filterBox {
    padding: var(--spacing-20px) var(--spacing-20px) 0;
    width: 100%;
    border-bottom: 1px solid var(--grey-3);
}

ul.filterItemList {
    list-style: none;
    padding: var(--spacing-16px) 0;
}

.filterBox .heading{
    font-size: 14px;
    color: var(--black-5);
    font-family: 'ns-bold';
}

.radioGrpFlex{
    padding: var(--spacing-16px) 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-32px);
    width: 100%;
}

.radioGrpFlex .radioBtn{
    font-size: 14px;
}

.filterMenuHeader .heading{
    font-family: 'ns-bold';
}

.filterMenuFooter{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: var(--spacing-16px) var(--spacing-20px);
    width: 100%;
    gap: var(--spacing-8px);
    border-top: 1px solid var(--grey-3);
}

.feedbackEmoji .icon:first-child.active svg path{
    fill: var(--success-5);
}

.feedbackEmoji .icon:last-child.active svg path{
    fill: var(--danger-5);
}