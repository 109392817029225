.attendaceContainerOuter{
    width: 100%;
    display: flex;
    padding: var(--spacing-20px);
    align-items: flex-start;
    justify-content: center;
    height: calc(100vh - 110px);
}
.attendaceContainer{
    width: 800px;
    margin: auto;
    height: 100%;
    overflow: auto;
    background: var(--white);
    border: 1px solid var(--grey-3);
    border-radius: 6px;
}

.attendanceHeader {
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: var(--spacing-20px);
    border-bottom: 1px solid var(--grey-3);
    position: sticky;
    top: 0;
    z-index: 1;
}
.attendanceBoxHeader .heading{
    font-family: 'ns-semibold';
    font-size: 16px;
    color: var(--black-4);
}
.attendanceBody{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
}

.attendanceBox {
    width: 100%;
    padding: var(--spacing-20px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.attendanceBoxHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-16px);
}

.percent{
    display: block;
    width: 100%;
    margin-top: var(--spacing-8px);
    text-align: right;
    color: var(--success-4);
    font-size: 16px;
    font-family: 'ns-semibold';
}