.rating-wrapper {
    display: flex;
    justify-content: center;
    min-height: 55px;
    align-items: center;
    border: 1px solid var(--grey-4);
    border-radius: 10px;
    background: var(--warning-1);
    width: 100%;
    max-width: 350px;
    margin: 30px auto 0;
  }
  
  .rating {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: center;
    overflow: hidden;
    flex-direction: row-reverse;
    min-height: inherit;
    position: relative;
  }
  
  .rating-0 {
    filter: grayscale(100%);
  }
  
  .rating > input {
    display: none;
  }
  
  .rating > label {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-image: url("https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/star.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: 0.3s;
  }
  .rating > label:first-child {
    margin-right: 0;
  }
  .rating > input:checked ~ label,
  .rating > input:checked ~ label ~ label {
    background-image: url("https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/star-fill.svg");
  }
  
  .rating > input:not(:checked) ~ label:hover,
  .rating > input:not(:checked) ~ label:hover ~ label {
    background-image: url("https://epicenter2022.s3.ap-south-1.amazonaws.com/assets/img/star-fill.svg");
  }
  
  .rating-count {
    font-size: 16px;
    font-family: "ns-semibold";
  }
  
  .rating-wrapper label::before{
    display: none;
  }

  .rating-wrapper label{
    padding-left: 0 !important;
    width: 100% !important;
    max-width: 30px;
  }