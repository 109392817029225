.interviewSchedulerContainer{
    max-width: 1010px;
    width: 100%;
    height: max-content;
    margin: 20px auto;
    border: 1px solid var(--grey-3);
    border-radius: 10px;
    background: var(--white);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
}
.interviewSchedulerHeader{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--black-5);
    padding: var(--spacing-20px);
}

.interviewSchedulerHeader .heading{
    color: var(--white);
    font-size: 16px;
    font-family: 'ns-semibold';
}
.interviewSchedulerContainerInner{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.interviewSchedulerColumeOne{
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 350px;
    padding: 10px;
    border-right: 1px solid var(--grey-3);
}
.interviewSchedulerColumeTwo{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 10px 15px;
    border-right: 1px solid var(--grey-3);
}
.interviewSchedulerColumeTwo .react-calendar{
    width: 100%;
    max-width: 100%;
    border: none;
}
.interviewSchedulerColumeTwo .react-calendar__tile{
    padding: 18px;
    max-height: max-content;
}
.interviewSchedulerColumeTwo .react-calendar .react-calendar__navigation__arrow{
    border-radius: 50%;
}

.interviewSchedulerColumeThree{
    width: 250px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.slot{
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    padding: 6px;
    font-size: 14px;
    font-family: 'ns-semibold';
    color: var(--primary-4);
    width: 100%;
    border-radius: 6px;
    border: 2px solid var(--primary-4);
}

.slots.active .slot{
    width: 100%;
    background: var(--black-5);
    border-color: var(--black-5);
    color: var(--white);
}
.slots{
    width: 100%;
    display: flex;
    gap: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.slots.active .primaryBtn{
    width: 100%;
    display: block;
}

.slots .primaryBtn{
    display: none;
}

.slotsOuter{
    margin-top: var(--spacing-16px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-8px);
}

.interviewSchedulerColumeThree .month{
    font-size: 14px;
}

.interviewSchedulerColumeOne .studentName{
    display: flex;
   
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.interviewSchedulerColumeOne .studentName img{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid var(--black-5);
    object-fit: fill;
}
.interviewDetails {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-4px);
}

.interviewDetails p {
    font-size: 20px;
    margin-left: 4px;
    font-family: 'ns-semibold';
}

.interviewDesc p {
    padding: 0px 6px;
    font-size: 12px;
    color: var(--black-1);
}

.studentDetailsInfo{
    margin-top: var(--spacing-16px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-8px);
    flex-direction: column;
}

.interviewConfirmationContainer{
    width: 700px;
    margin: var(--spacing-20px) auto;
    border: 1px solid var(--grey-3);
    padding: 20px;
    background: var(--white);
    overflow: hidden;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: var(--spacing-24px);
}

.interviewConfirmDetails {
    gap: var(--spacing-12px);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.interviewConfirmDetails .heading{
    font-size: 32px;
    font-family: 'ns-bold';
}
.interviewConfirmDetails .text{
    font-size: 14px;
}
.interviewConfirmDetails .details{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.interviewConfirmDetails .text b{
    font-family: 'ns-bold';
}

.interviewConfirmDetails .emails{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    font-size: 14px;
}

.meetingLink{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-4px);
    padding: 12px 0;
    border: 1px solid var(--grey-3);
    border-left: none;
    border-right: none;
}

.meetingLink a{
    text-decoration: none;
    color: var(--primary-5);
    font-family: 'ns-semibold';
}
.interviewSchedulerColumeOne .studentName .small-name{
    font-size: 12px;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}
.react-calendar__month-view__days__day--weekend {
    color: inherit;
}
.react-calendar__month-view__days__day--weekend:disabled {
    color: rgba(16, 16, 16, 0.3) ;
}
.react-calendar__tile:disabled {
    background-color: #f7f7f7;
}
.react-calendar__tile--active {
    color: #f7f7f7 !important;
}

.react-calendar__tile--active:enabled:hover, 
.react-calendar__tile--active:enabled:focus{
    background-color: var(--primary-4) !important;
    color: #f7f7f7 !important;
    transition: 0.1s ease-in !important;
}

.guidelinesContainer{
    padding: var(--spacing-20px);
    border: 1px solid var(--grey-3);
    background: var(--white);
    max-width: 1010px;
    margin: 20px auto;
    border-radius: 10px;
}
.guidelinesList{
    padding: var(--spacing-20px) 10px;
    margin-top: var(--spacing-12px);
    gap: var(--spacing-8px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    border-top: 1px solid var(--grey-3);
}

.guideline{
    display: flex;
    gap: var(--spacing-8px);
    color: var(--black-2);
    font-size: 14px;
}

.guideline > svg{
    min-width: 16px;
}



.interviewDescType2{
    padding: var(--spacing-8px);
    width: 100%;
    border-radius: 5px;
    background: var(--grey-2);
}

.interviewDescType2 p{
    font-size: 12px;
    color: var(--black-2);
}
.interviewDescType2 p b{
    font-family: 'ns-bold';
}

.slot-booked-link {
    cursor: pointer;
    color: #008cff;
    font-weight: bold;
}

.slot-booked-link:hover {
    color: #0051ff;
    text-decoration: underline;
}

.confirmouter {
    width: 100%;
    max-width: 1080px;
    margin: auto;
    background: url(/public/assets/img/confirmbg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 30px;
}

.confirm-inner {
    background: #fff;
    max-width: 650px ;
    width: 100%;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
}

.confirm-inner img{
    max-width: 100%;
}

.gtag {
    padding: 5px 15px;
    background: #DDEBE5;
    color: #166343;
    border: 1px solid #D9E9F9;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.link{
    color: #004E9A;
    text-decoration: none;
    font-style: italic;
    cursor: pointer;
}

.innerbox{
    background: #f7f8f8; 
    padding: 25px 15px;
    border-radius: 10px;
}

.interviewSchedulerContainerOuter{
    width: 100%;
    height: 100vh;
    overflow: auto;
}
.congratsOuter{
    width: 100%;
    border-bottom: 1px solid var(--grey-3);
    padding: var(--spacing-20px) var(--spacing-24px);
}
.congratsBox{
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-32px);
    background: var(--warning-1);
    width: 100%;
    padding: var(--spacing-16px);
}

.congratsInner{
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8px);
}
.congratsInner p{
    font-size: 16px;
    font-family: 'ns-regular';
}

.congratsInner p b{
    font-family: 'ns-bold';
}

.interviewSchedulerContainer .footer{
    text-align: center;
    border-top: 1px solid var(--grey-3);
    padding: var(--spacing-8px);
    background: var(--grey-1);
    width: 100%;
}

.footer p{
    font-size: 14px;
    font-family: 'ns-semibold';
}

.deadlineOuter{
    margin: 0;
    background: var(--white);
}

.tags{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.interviewSchedulerContainerOuter .footer{
    width: 100%;
    max-width: 1080px;
    margin: auto;
    text-align: center;
    background: var(--grey-1);
    padding: 8px;
    border-top: 1px solid var(--grey-3);
}

.deadlineHeading{
    font-size: 32px;
    margin-bottom: 20px;
    font-family: 'ns-semibold';
}

.dealineContainerOuter{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 1080px;
    margin: auto;
}





.interviewSchedulerNew{
    overflow: auto;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    height: calc(100vh - 65px);
    padding: var(--spacing-20px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--spacing-16px);
}

.interviewSchedulerNew::-webkit-scrollbar{
    display: none;
}

.interviewSchedulerNewRightContainer{
    max-width: 440px;
    width: 100%;
    background: var(--white);
    border: 1px solid var(--grey-3);
    border-radius: 5px;
    height: max-content;
}

.interviewSchedulerNewLeftContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.interviewSchedulerNewLeftContainer .guidelinesList{
    background: var(--white);
    margin: 0;
    border: 1px solid var(--grey-3);
    border-radius: 5px;
}

.newCongratsBox{
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--grey-3);
    padding: var(--spacing-20px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background: url('https://cdn.mastersunion.org/assets/imgV2/newCongratsBg.png') var(--white);
}

.newCongratsBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.congratBoxContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

.congratBoxContent .name{
    font-size: 32px;
    font-family: 'ns-semibold';
}


.congratBoxContent .congratsText{
    font-size: 16px;
    font-family: 'ns-regular';
}


.congratBoxContent .congratsText b{
    font-family: 'ns-bold';
    line-height: 150%;
}

.interviewDetailsBox{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    background: var(--white);
    border: 1px solid var(--grey-3);
    padding: 20px;
    border-radius: 5px;
}

.interviewDetailsContent{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.warningBox{
    padding: var(--spacing-16px);
    border-radius: 5px;
    background: var(--warning-1); 
}

.interviewDetailsContent .congratsText{
    font-size: 14px;
    line-height: 150%;
}

.warningBox p{
    font-size: 14px;
    line-height: 150%;
}

.interviewSchedulerNewRightContainer .interviewSchedulerColumeTwo{
    border: none;
    padding: var(--spacing-20px);
    border-bottom: 1px solid var(--grey-3);
    width: 100%;
}

.interviewSchedulerNewRightContainer .interviewSchedulerColumeThree{
    width: 100%;
}

.interviewSchedulerNewRightContainer .interviewSchedulerColumeTwo .react-calendar{
    padding: 0;
}


@media (max-width:500px){
    .interviewSchedulerNewLeftContainer .guidelinesList{
        padding: 10px;
    }
    .interviewSchedulerNew{
        flex-direction: column;
        height: max-content;
    }
    .newCongratsBox{
        padding: 10px;
        flex-direction: column;
        gap: 20px;
    }
    .interviewDetailsBox{
        padding: 10px;
        flex-direction: column;
    }
    .guideline p {
        width: calc(100% - 20px);
    }
    .interviewSchedulerNewRightContainer .interviewSchedulerColumeTwo{
        padding: 10px;
    }
    .interviewSchedulerHeader{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }
}
.interviewSchedulerNewRightContainer .slotsOuter{
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.interviewSchedulerNewRightContainer .slots{
    width: 49%;
}

.confirmInnerBox{
    justify-content: center;
}