.meetingSelector {
    width: 49%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.meetingSelector .checkboxContainer{
    margin-bottom: 0;
    font-size: 14px;
    font-family: 'ns-semibold';
    padding-left: 30px;
}
.meetingSelector .dropdownBox{
    width: 100% !important;
}