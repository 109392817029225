/* ================== PRIMARY BTN ========================= */
.primaryBtn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 120%;
  border-radius: 8px;
  padding: 8px 20px;
  color: var(--white);
  background: var(--primary-4);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--primary-4);
  text-decoration: none;
  text-transform: capitalize;
}

.primaryBtn:hover {
  border: 1px solid var(--primary-5);
  background: var(--primary-5);
}

.primaryBtn:focus {
  border: 1px solid var(--primary-5);
  background: var(--primary-5);
}

.primaryBtn:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.primaryBtn:disabled img {
  filter: brightness(0.5);
}

.btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* ================== PRIMARY BTN ========================= */

/* ========================== primaryBtnSmall ========================= */

.primaryBtnSmall {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 120%;
  border-radius: 8px;
  padding: 6px 16px;
  color: var(--white);
  background: var(--primary-6);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  text-decoration: none;
}

.primaryBtnSmall:hover {
  background: var(--primary-4);
}

.primaryBtnSmall:focus {
  background: var(--primary-5);
}

.primaryBtnSmall:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
}

.primaryBtnSmall:disabled img {
  filter: brightness(0.5);
}

.primaryBtnSmall img {
  width: 14px;
}

.primaryBtnSmall .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.primaryBtnSmall .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.primaryBtnSmall .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* =============================== secondary btns ============================= */

.secondaryBtn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 120%;
  border-radius: 8px;
  padding: 8px 20px;
  color: var(--primary-6);
  background: var(--primary-6);
  transition: 0.3s;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--primary-6);
}

.secondaryBtn:hover {
  border: 1px solid var(--primary-4);
  color: var(--primary-4);
}

.secondaryBtn:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.secondaryBtn:focus {
  border: 1px solid var(--primary-5);
  color: var(--primary-5);
}

.secondaryBtn:disabled img {
  filter: grayscale(1);
}

.secondaryBtn .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.secondaryBtn .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.secondaryBtn .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* =============================== secondary btns ============================= */
/* =============================== secondary btns small ============================= */
.secondaryBtnSmall {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 120%;
  border-radius: 8px;
  padding: 6px 16px;
  color: var(--primary-6);
  background: var(--primary-6);
  transition: 0.3s;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--primary-6);
}

.secondaryBtnSmall:hover {
  border: 1px solid var(--primary-4);
  color: var(--primary-4);
}

.secondaryBtnSmall:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.secondaryBtnSmall:focus {
  border: 1px solid var(--primary-5);
  color: var(--primary-5);
}

.secondaryBtnSmall:disabled img {
  filter: grayscale(1);
}

.secondaryBtnSmall .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.secondaryBtnSmall .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.secondaryBtnSmall .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* =============================== secondary btns small ============================= */
/* =============================== dark btns  ============================= */
.darkBtn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 120%;
  border-radius: 8px;
  padding: 8px 20px;
  color: var(--white);
  background: var(--black-4);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--black-4);
  text-decoration: none;
  text-transform: capitalize;
}

.darkBtn:hover {
  border: 1px solid var(--black-2);
  background: var(--black-2);
}

.darkBtn:focus {
  border: 1px solid var(--black-5);
  background: var(--black-5);
}

.darkBtn:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.darkBtn:disabled img {
  filter: brightness(0.5);
}

.btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* =============================== dark btns  ============================= */

/* ========================== darkBtnSmall ========================= */

.darkBtnSmall {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 120%;
  border-radius: 8px;
  padding: 6px 16px;
  color: var(--white);
  background: var(--black-4);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  text-decoration: none;
}

.darkBtnSmall:hover {
  background: var(--black-2);
}

.darkBtnSmall:focus {
  background: var(--black-5);
}

.darkBtnSmall:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
}

.darkBtnSmall:disabled img {
  filter: brightness(0.5);
}

.darkBtnSmall img {
  width: 14px;
}

.darkBtnSmall .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.darkBtnSmall .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.darkBtnSmall .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* ========================== darkBtnSmall ========================= */

/* ================== SUCCESS BTN ========================= */
.successBtn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 120%;
  border-radius: 8px;
  padding: 8px 20px;
  color: var(--white);
  background: var(--success-6);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--success-6);
  text-decoration: none;
  text-transform: capitalize;
}

.successBtn:hover {
  border: 1px solid var(--success-5);
  background: var(--success-5);
}

.successBtn:focus {
  border: 1px solid var(--success-6);
  background: var(--success-6);
}

.successBtn:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.successBtn:disabled img {
  filter: brightness(0.5);
}

.btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* ========================== primaryBtnSmall ========================= */

.successBtnSmall {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 120%;
  border-radius: 8px;
  padding: 6px 16px;
  color: var(--white);
  background: var(--success-5);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  text-decoration: none;
}

.successBtnSmall:hover {
  background: var(--success-4);
}

.successBtnSmall:focus {
  background: var(--success-6);
}

.successBtnSmall:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
}

.successBtnSmall:disabled img {
  filter: brightness(0.5);
}

.successBtnSmall img {
  width: 14px;
}

.successBtnSmall .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.successBtnSmall .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.successBtnSmall .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

.successOutlineBtn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 120%;
  border-radius: 8px;
  padding: 8px 20px;
  color: var(--success-6);
  background: var(--success-6);
  transition: 0.3s;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--success-6);
  text-transform: capitalize;
}

.successOutlineBtn:hover {
  border: 1px solid var(--success-4);
  color: var(--success-4);
}

.successOutlineBtn:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.successOutlineBtn:focus {
  border: 1px solid var(--success-5);
  color: var(--success-5);
}

.successSecondaryBtn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 120%;
  border-radius: 8px;
  padding: 8px 20px;
  color: var(--primary-6);
  background: var(--primary-6);
  transition: 0.3s;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--primary-6);
}

.successSecondaryBtn:hover {
  border: 1px solid var(--primary-4);
  color: var(--primary-4);
}

.successSecondaryBtn:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.successSecondaryBtn:focus {
  border: 1px solid var(--primary-5);
  color: var(--primary-5);
}

.successSecondaryBtn:disabled img {
  filter: grayscale(1);
}

.successSecondaryBtn .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.successSecondaryBtn .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.successSecondaryBtn .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* ================== SUCCESS BTN ========================= */

/* ========================== primaryBtnSmall ========================= */

.primaryBtnSmall {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 120%;
  border-radius: 8px;
  padding: 6px 16px;
  color: var(--white);
  background: var(--primary-6);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  text-decoration: none;
}

.primaryBtnSmall:hover {
  background: var(--primary-4);
}

.primaryBtnSmall:focus {
  background: var(--primary-5);
}

.primaryBtnSmall:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
}

.primaryBtnSmall:disabled img {
  filter: brightness(0.5);
}

.primaryBtnSmall img {
  width: 14px;
}

.primaryBtnSmall .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.primaryBtnSmall .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.primaryBtnSmall .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* =============================== secondary btns ============================= */

.secondaryBtn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 120%;
  border-radius: 8px;
  padding: 8px 20px;
  color: var(--primary-6);
  background: var(--primary-6);
  transition: 0.3s;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--primary-6);
}

.secondaryBtn:hover {
  border: 1px solid var(--primary-4);
  color: var(--primary-4);
}

.secondaryBtn:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.secondaryBtn:focus {
  border: 1px solid var(--primary-5);
  color: var(--primary-5);
}

.secondaryBtn:disabled img {
  filter: grayscale(1);
}

.secondaryBtn .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.secondaryBtn .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.secondaryBtn .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* =============================== secondary btns ============================= */
/* =============================== danger outline btns ================================ */
.dangerOutlineBtn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 120%;
  border-radius: 8px;
  padding: 8px 20px;
  color: var(--danger-6);
  background: var(--danger-6);
  transition: 0.3s;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--danger-6);
}

.dangerOutlineBtn:hover {
  border: 1px solid var(--danger-4);
  color: var(--danger-4);
}

.dangerOutlineBtn:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.dangerOutlineBtn:focus {
  border: 1px solid var(--danger-5);
  color: var(--danger-5);
}

.dangerOutlineBtnSmall {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 120%;
  border-radius: 8px;
  padding: 6px 18px;
  color: var(--danger-6);
  background: var(--danger-6);
  transition: 0.3s;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--danger-6);
}

.dangerOutlineBtnSmall:hover {
  border: 1px solid var(--danger-4);
  color: var(--danger-4);
}

.dangerOutlineBtnSmall:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.dangerOutlineBtnSmall:focus {
  border: 1px solid var(--danger-5);
  color: var(--danger-5);
}

/* =============================== danger outline btns ================================ */

/* =============================== OUTLINE BTN SMALL ======================================= */

.successSecondaryBtnSmall {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 120%;
  border-radius: 8px;
  padding: 6px 18px;
  color: var(--success-6);

  transition: 0.3s;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--success-6);
  text-transform: capitalize;
}

.successSecondaryBtnSmall:hover {
  border: 1px solid var(--success-4);
  color: var(--success-4);
}

.successSecondaryBtnSmall:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.successSecondaryBtnSmall:focus {
  border: 1px solid var(--success-5);
  color: var(--success-5);
}

.successSecondaryBtnSmall:disabled img {
  filter: grayscale(1);
}

.successSecondaryBtnSmall .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.successSecondaryBtnSmall .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.successSecondaryBtnSmall .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* dark small outline */
.darkSecondaryOutlineBtnSmall {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 120%;
  border-radius: 8px;
  padding: 6px 18px;
  color: var(--black-5);

  transition: 0.3s;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--black-5);
  text-transform: capitalize;
}

.darkSecondaryOutlineBtnSmall:hover {
  border: 1px solid var(--black-4);
  color: var(--black-4);
}

.darkSecondaryOutlineBtnSmall:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.darkSecondaryOutlineBtnSmall:focus {
  border: 1px solid var(--black-5);
  color: var(--black-5);
}

.darkSecondaryOutlineBtnSmall:disabled img {
  filter: grayscale(1);
}

.darkSecondaryOutlineBtnSmall .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.darkSecondaryOutlineBtnSmall .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.darkSecondaryOutlineBtnSmall .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* =============================== OUTLINE BTN SMALL ======================================= */

/* =============================== OUTLINE BTN  ======================================= */

.darkSecondaryOutlineBtn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 120%;
  border-radius: 8px;
  padding: 8px 20px;
  color: var(--black-5);

  transition: 0.3s;
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--black-5);
  text-transform: capitalize;
}

.darkSecondaryOutlineBtn:hover {
  border: 1px solid var(--black-4);
  color: var(--black-4);
}

.darkSecondaryOutlineBtn:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.darkSecondaryOutlineBtn:focus {
  border: 1px solid var(--black-5);
  color: var(--black-5);
}

.darkSecondaryOutlineBtn:disabled img {
  filter: grayscale(1);
}

.darkSecondaryOutlineBtn .btnDropdown {
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: var(--border-1);
  box-shadow: var(--shadow-1);
  top: 100%;
  list-style: none;
  display: flex;
  position: absolute;
  flex-direction: column;
  background: var(--white);
  width: max-content;
  left: 0;
  transition: 0.3s;
  z-index: 100;
}

.darkSecondaryOutlineBtn .btnDropdown.active {
  opacity: 1;
  visibility: visible;
}

.darkSecondaryOutlineBtn .btnDropDownItem {
  gap: 5px;
  font-size: 14px;
  line-height: 20px;
  color: var(--black-5);
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: var(--border-1);
}

/* =============================== OUTLINE BTN  ======================================= */

/* =============================== DANGER BTN ========================================= */
.dangerBtn {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 120%;
  border-radius: 8px;
  padding: 8px 20px;
  color: var(--white);
  background: var(--danger-4);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--danger-4);
  text-decoration: none;
  text-transform: capitalize;
}

.dangerBtn:hover {
  border: 1px solid var(--danger-5);
  background: var(--danger-5);
}

.dangerBtn:focus {
  border: 1px solid var(--danger-5);
  background: var(--danger-5);
}

.dangerBtn:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.dangerBtn:disabled img {
  filter: brightness(0.5);
}

.dangerBtnSmall {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  line-height: 120%;
  border-radius: 8px;
  padding: 6px 18px;
  color: var(--white);
  background: var(--danger-4);
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border: 1px solid var(--danger-4);
  text-decoration: none;
  text-transform: capitalize;
}

.dangerBtnSmall:hover {
  border: 1px solid var(--danger-5);
  background: var(--danger-5);
}

.dangerBtnSmall:focus {
  border: 1px solid var(--danger-5);
  background: var(--danger-5);
}

.dangerBtnSmall:disabled {
  cursor: default;
  color: var(--grey-5);
  background: var(--grey-3);
  border: 1px solid var(--grey-3);
}

.dangerBtnSmall:disabled img {
  filter: brightness(0.5);
}
/* =============================== DANGER BTN ========================================= */
