.feedback-wrapper{
    align-items: center;
    justify-content: center;
    height: calc(100vh - 125px);
}

.with-card .question-box{
    background: var(--white);
    box-shadow: 5px 5px var(--warning-2);
    border: 1px solid var(--black-5);
    border-radius: 5px;
}

.question-box{
    max-width: 900px;
    width: 100%;
    padding: 40px;
    text-align: center;
    min-height: 340px;
    max-width: 640px;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.question-box a{
    text-decoration: none;
    color: var(--primary-5);
}

.heading-2{
    font-size: 32px;
    line-height: 40px;
    font-family: 'ss-bold';
}

.text-3{
    font-size: 16px;
    line-height: 22px;
    font-family: 'ns-regular';
    color: var(--black-3);
}

.feedbackDangerBtn{
    background: var(--danger-5);
    padding: 18px 40px;
    width: 100%;
    color: var(--white);
    font-family: "ns-bold";
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    max-width: 170px;
} 

span.important{
    color: var(--danger-5);
}
.inside-shell{
    width: 100%;
}

.checkmarkText{
    width: 100%;
    font-size: 16px;
}

.question-box .heading-2 {
    font-size: 20px;
    width: 100%;
    line-height: 150%;
    text-align: center;
}

.question-box .text-3{
    text-align: justify;
}